<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <div >
        <van-sticky>
          <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
        </van-sticky>
      </div>

      <van-form @submit="onSubmit" style="padding-bottom: 60px;">
        <div >
          <van-field
            v-model="course.title"
            name="coursetitle"
            label="标题"
            placeholder="请输入标题"
            required
            :rules="[{ required: true, message: '请输入标题' }]"
          />

          <van-field name="uploader" label="个人照片（课程列表的封面图）">
            <template #input>
              <van-uploader
                v-model="fileList"
                multiple
                :max-count="1"
                :after-read="afterReadAudio"
              />
            </template>
          </van-field>

          <van-field
            v-model="typefieldValue"
            is-link
            readonly
            label="主题"
            placeholder="点击选择主题"
            @click="showType = true"
          />
          <van-popup v-model="showType" round position="bottom">
            <van-cascader
              v-model="cascaderValue"
              title="请选选择主题"
              :options="ccitems"
              @close="showType = false"
              @change="typeonChange"
              @finish="typeonFinish"
            />
          </van-popup>

          <div class="van-cell van-field van-cell--required">
            <div style="color: #646566">
              <span>详细内容(图文混排)</span>
              <span>&nbsp;</span>
              <div style="float: right; margin-left: 20px" @click="toediteInfo">
                <van-icon name="info-o" /><span>点击查看编辑器帮助</span>
              </div>
            </div>
          </div>
        </div>
        <QuillEditor
          ref="editor2"
          v-model="course.detailsHtml"
          @onInputHandler="onInput"
        />
        <!--上传预览-->
        <div class="van-hairline--top-bottom"  v-show="imagefileList.length > 0">
          <van-row>
            <van-col span="24">
              <van-uploader
                multiple
                v-model="imagefileList"
                :max-count="20"
                :after-read="afterReadImage"
              />
            </van-col>
          </van-row>
        </div>
        <div
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 5px 5px 5px 5px;
            background-color: #ededed;
          "
          class="addbottom"
        >
          <van-row>
            <van-col span="12">
              <van-uploader
                multiple
                :max-count="20"
                :after-read="afterReadImageOut"
              >
                <div>
                  <van-icon size="30" name="/image/image.png" />
                </div>
              </van-uploader>
            </van-col>
            
            <van-col span="12">
              <div style="padding-right: 10px">
                <van-button
                  size="small"
                  round
                  block
                  type="info"
                  native-type="submit"
                  >提交</van-button
                >
              </div>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

import { uploadfile } from "@/api/file";
import { AddFromH5 } from "@/api/courseoffline";

import { getClassWithChildren } from "@/api/school";

import QuillEditor from "../components/quillEditor";

export default {
  name: "addCourse",
  data() {
    return {
      imagefileList: [],
      editoroninput: false,

      s7topicGroup: [],
      s7topicList: [],
      title: "授课意向提报",

      typefieldValue: "",
      showType: false,

      cascaderValue: "",
      fileList: [],

      course: {
        title: "",
        imageUrl: "",
        detailsHtml: "<p>课程内容介绍：（课程内容简介或提纲）</p><p><br></p><p>授课时长：（时）</p><p><br></p><p>讲师介绍：（在公司发展历程或取得成绩）</p><p><br></p><p>讲师座右铭：</p>",
        schoolClass : 0,
        schoolClass2: 0,
      },

      ccitems: [],
      loading: true,
    };
  },
  components: {
    QuillEditor,
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.loading = true;
        this.initData();
      }
    },
  },
  mounted() {},
  computed: {},
  methods: {
    async initData() {
      await this.loadinitData();
      this.loading = false;
    },
    loadinitData: async function () {
      //let that = this;

      let cresult = await getClassWithChildren({
        fromType: 1,
        filterData: { parentID: 1 },
      });

      this.ccitems = [];
      cresult.data.data.forEach((citem) => {
        let chilrenitem = [];
        citem.children.forEach((chitem) => {
          chilrenitem.push({ text: chitem.name, value: chitem.id });
        });
        this.ccitems.push({
          text: citem.name,
          value: citem.id,
          children: chilrenitem,
        });
      });
    },

    toediteInfo() {
      this.$router.push({
        path: "/article/detail?para=307dce29-bccf-4a1d-a704-3ea039d517f3&type=104",
      });
    },
    afterReadImage: async function (files) {
      Toast("文件上传中");
      //多张图片循环上传
      var filelist = [];

      if (Object.prototype.toString.call(files) === "[object Array]") {
        files.reverse();//根据选择顺序上传
        files.forEach((element) => {
          filelist.push(element.file);
        });
      } else {
        filelist.push(files.file);
      }
      for (let index = 0; index < filelist.length; index++) {
        let formData = new FormData();
        formData.append("file", filelist[index]);
        let aresult = await uploadfile(formData);
        let url = aresult.data; // Get url from response
        this.imagefileList[
          this.imagefileList.length - filelist.length + index
        ].url = url;
      }

      Toast("文件上传成功");
    },
    //点击下方的上传按钮
    afterReadImageOut: async function (files) {
      Toast("文件上传中");
      //多张图片循环上传
      var filelist = [];
      if (Object.prototype.toString.call(files) === "[object Array]") {
        files.reverse();//根据选择顺序上传
        files.forEach((element) => {
          filelist.push(element.file);
        });
      } else {
        filelist.push(files.file);
      }

      for (let index = 0; index < filelist.length; index++) {
        let formData = new FormData();
        formData.append("file", filelist[index]);
        let aresult = await uploadfile(formData);

        let url = aresult.data; // Get url from response
        this.imagefileList.push({
          url: url,
          imageFit: "contain",
          previewSize: 80,
        });

        if (this.imagefileList.length == 20) {
          break;
        }
      }

      Toast("文件上传成功");
    },

    onInput: function (input) {
      console.log(input);
      console.log("oninput");
      this.editoroninput = input;
    },
    onSubmit: async function () {
      let baseinfo = "";
      this.course.detailsHtml = baseinfo + this.$refs.editor2.content;

      if (this.course.detailsHtml.length < 1) {
        Toast("请填写详细内容");
        return;
      }
      if (this.imagefileList.length > 0) {
        this.imagefileList.forEach((element) => {
          this.course.detailsHtml += "<img src='" + element.url + "'/>";
        });
      }

      const data = await AddFromH5(this.course);
      if (data.data.success) {
        if (this.course.systemType != 0) {
          Toast("提交成功");
        }

        this.course.detailsHtml = "";
        this.course.title = "";
        this.cascaderValue = "";
        this.typefieldValue = "";

        this.$router.back();
      } else {
        Toast(data.data.errorMessage);
      }
    },

    afterReadAudio: async function (files) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfile(formData);

      let url = aresult.data; // Get url from response
      this.course.imageUrl = url;

      this.fileList = [];
      this.fileList.push({
        url: url,

        imageFit: "contain",
        previewSize: 80,
      });

      Toast("文件上传成功");
    },

    async typeonChange({ value, tabIndex }) {
      if (tabIndex == 0) {
        this.course.schoolClass = value;
        this.course.schoolClass2 = 0;
      }
      if (tabIndex == 1) {
        this.course.schoolClass2 = value;
      }

      //this.typefieldValue = value.map((option) => option.text).join('/');
    },
    typeonFinish({ selectedOptions }) {
      this.showType = false;
      console.log(selectedOptions);

this.course.schoolClass= selectedOptions[0].value;
 this.course.schoolClass2= selectedOptions[1].value;

      this.typefieldValue = selectedOptions
        .map((option) => option.text)
        .join("/");
    },
  },
};
</script>

<style>
/* .ql-fullscreen {
  width: 20px !important;
  background: url("/image/fullscreen.svg") no-repeat center !important;
} */
</style>
<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-checkbox {
    margin-top: 5px;
  }
  /deep/ .btn-box {
    margin-top: 25px;
  }
}
</style>